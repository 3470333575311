import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSession } from "providers/SessionProvider";
import { getProfile } from "../services/home";
import { useTranslation } from "react-i18next";
import { clearSessionData } from "services/session.service";
import { unsubscribe } from "services/vas-auth-api";
import { getProfileImage } from "utils/app.util";
import Notification from "./notification/Notification";
import { routes } from "constants/routes";

const Profile = () => {
    const navigate = useNavigate();
    const { user, getUser } = useSession();
    const [responseMessage, setResponseMessage] = useState({
        message: "",
        isSuccess: true,
    });

    const onLogout = () => {
        clearSessionData();
        getUser();
        navigate(routes.login);
    };
    const [profile, setProfile] = useState([]);
    const { t } = useTranslation();

    async function handleUnsubscribe() {
        try {
            setResponseMessage((res) => ({ ...res, message: "" }));
            await unsubscribe();
            onLogout();
        } catch (ex) {
            console.log(ex);
            setResponseMessage({ message: ex.message, isSuccess: false });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getProfile();
                setProfile(res);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, []);

    const redirectToPage = (page) => {
        navigate(page);
    };

    return (
        <div className="home">
            <div className="main">
                <div className="container box-wrapper">
                    <div className="header">
                        <div className="row align-items-center">
                            <div className="col-6 text-start">
                                <div className="logo d-flex">
                                    <Link to="/">
                                        <img
                                            src="/image/Logo_new.png"
                                            alt="Logo"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home-profile">
                        <div className="row gx-0">
                            <div className="col-7 text-start">
                                <div className="d-flex align-items-center">
                                    <div className="avatar">
                                        <img
                                            src={getProfileImage(
                                                profile?.image
                                            )}
                                            alt="avatar"
                                            className="avatar"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-2">
                                        <div className="profile-info">
                                            <h4 className="profile-name">
                                                {profile?.name}{" "}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5 text-end">
                                <div className="h-100 d-flex align-items-center justify-content-end">
                                    <span className="profile-subscriber">
                                        {" "}
                                        {t("7")}{" "}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {Boolean(user.is_balance_expired) && (
                            <div className="m-1 p-2 expired">
                                <p className="m-0">{t("111")}</p>
                            </div>
                        )}
                    </div>

                    <div className="content-box">
                        <ul className="profile-list-wrapper">
                            <li
                                className="profile-list"
                                onClick={() => {
                                    redirectToPage("/history");
                                }}
                            >
                                <div className="d-flex w-100">
                                    <div className="profile-list-pict">
                                        <img src="/image/controller.png" alt="History" className="game-icon" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('9')}</h4>
                                    </div>
                                </div>
                            </li>
                            <li className="profile-list" onClick={() => { redirectToPage('/edit') }}>
                                <div className="d-flex w-100">
                                    <div className="profile-list-pict">
                                        <img src="/image/Setting_group.png" alt="Edit Profile" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('10')}</h4>
                                    </div>
                                </div>
                            </li>

                            <li className="profile-list" onClick={() => { redirectToPage('/language') }}>
                                <div className="d-flex w-100">
                                    <div className="profile-list-pict">
                                        <img src="/image/language.png" alt="Languages" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('11')}</h4>
                                    </div>
                                </div>
                            </li>
                            <li className="profile-list" onClick={() => { redirectToPage('/policy') }}>
                                <div className="d-flex w-100">
                                    <div className="profile-list-pict">
                                        <img src="/image/note.png" alt="note" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('12')}</h4>
                                    </div>
                                </div>
                            </li>
                            <li className="profile-list" onClick={() => { redirectToPage('/contact-us') }}>
                                <div className="d-flex w-100">
                                    <div className="profile-list-pict">
                                        <img src="/image/support.png" alt="support" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('13')}</h4>
                                    </div>
                                </div>
                            </li>
                            <li className="profile-list" onClick={onLogout}>
                                <div className="d-flex w-100" >
                                    <div className="profile-list-pict">
                                        <img src="/image/logout.png" alt="logout" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('14')}</h4>
                                    </div>
                                </div>
                            </li>
                            <li className="profile-list" onClick={handleUnsubscribe}>
                                <div className="d-flex w-100" >
                                    <div className="profile-list-pict">
                                        <img src="/assets/icons/unsubscribe.svg" alt="logout" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('8')}</h4>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Notification message={responseMessage.message} isSuccess={responseMessage.isSuccess} />
        </div>
    )
}

export default Profile;
