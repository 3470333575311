import { routes } from "constants/routes";
import { useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { authenticateWithISE2 } from "services/auth-api";
import Spinner from 'react-bootstrap/Spinner';
import { useSession } from "providers/SessionProvider";


const Register = () => {
    const {source} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { updateToken, updateUser } = useSession();

    useEffect(() => {
        const ise2 = searchParams.get("token");

        if (ise2) {
            authenticateWithISE2(ise2).then((res) => {

                if (res.data?.isExistingUser) {
                    updateToken(res.data?.token);
                } else {
                    updateUser({ ...res.data, ise2code: ise2 });
                }

                navigate(routes.home);
            }).catch(ex => {
                console.log(ex);
                navigate(routes.login);
            });
        } else if(source === "ussd") {
            updateUser({ ussd: true });
            navigate(routes.home);
        } else {
            navigate(routes.home);
        }
    }, [source, searchParams, navigate, updateToken, updateUser]);

    return (
        <div className="register">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default Register;
